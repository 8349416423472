.vitrine-container {
    width: auto;
    max-width: 1750px !important;
    height: auto;
    text-align: center;
    //min-height: 600px;

    img {
        max-width: 100%;
        height: auto;
    }
}

.imgSlide {
    border-radius: 4px;
    overflow: hidden;
}

.skeleton-vitrine {
    width: 100%;
    max-width: 330px;
    height: 343px;
    
    @media(min-width: 599px) {
        width: 575px;
        height: 600px;
    }
}

.top-banner-skeleton {
    height: 143.47px;

    @media(min-width: 599px) {
        min-width: 575px;
        min-height: 275px;
    }
}

.tituloBasicoAvulso {
    font-family: "Bellfort";
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    margin: 0;
    text-transform: uppercase;
    color: #4FBE95;
}
.splide__arrow svg {
    fill: #4FBE95;
}
.splide__pagination__page.is-active {
    background: #4FBE95;
}
.splide__pagination {
    bottom: -30px;
}
.subtituloAvulso {
    font-family: "Bellfort";
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
    text-transform: uppercase;
    color: #54412F;
}
.textAvulso {
    font-family: OpenSans;
    font-size: 16px;
    line-height: 21px;
    margin: 0;
    color: #54412F;
}
.radioContainer .textAvulso .b{
    display: block;
    margin-left: 23px;
    font-weight: 500;
    
}

.container-textAvulso {
    font-family: OpenSans;
    font-size: 16px;
    line-height: 21px;
    margin: 0;
    color: #54412F;
    min-height: 261px;
}

.container-dadosPessoais {
    min-height: 245px;
}

.textAvulsoSm {
    font-family: OpenSans;
    font-size: 14px;
    margin: 0;
    color: #54412F;
}


.radioContainer {
    margin-top: 8px;
    width: 100%;
    
}

.radioBtnSubstituicao {
    padding: 16px;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: #e0e0e0;
    width: 100%;

    label {
        cursor: pointer;
        width: 100%;
        margin-bottom: 0px;
    }

    div {
        line-height: 1.375;
    }

    & + div {
        margin-top: 8px;
    }

    &:hover {
        border-color: #4FBE95;
    }
}

.radioSelected {
    border-color: #4FBE95;
}

.radioContainer div input {
    display: none;
}

.radioContainer div input + div:before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    vertical-align: middle;
    background: #fff;
    border: 2px solid #e0e0e0;
    border-radius: 50%;
    margin-right: 8px;
    margin-top: -1.5px;
}

.radioContainer div input:checked + div:before {
    background: #FFF;
    box-sizing: border-box;
    border: 4.85px solid #4FBE95;
}

.textRadioBtnWithContainer {
    margin-left: 0;

    > div {
        display: inline;
    }
}

.textRadioBtnCard {
    margin-left: 0;

    > div {
        display: inline-flex;
    }
}

.textRadioBtn {
    //margin: 0;
    margin-left: 21px;
}

.input-data {
    width: 100%;
    max-width: 499px;
    height: 48px;

    display: flex;
    align-items: center;

    color: #614B36;

    border: 1px solid #e0e0e0e0;

    padding: 16px;
    border-radius: 4px;

    svg {
        font-size: medium;

        cursor: pointer;
    }

    input::-webkit-calendar-picker-indicator{
        display: none;
    }
    
    input[type="date"]::-webkit-input-placeholder{ 
        visibility: hidden !important;
    }

    input:focus {
        box-shadow: 0 0 0 0;
        outline: 0;  
    }

    input {
        flex: 1;
        max-width: 100%;
        height: 100% !important;
        border: none;
        outline: 0;
    }
}