/* div {
    margin: 8px;
} */

@keyframes spinning {
    from {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(360deg)
    }
}

.spinning {
    display: inline;
    animation-name: spinning;
    animation-duration: .5s;
    animation-iteration-count: infinite;

    margin: 0px 4px 0px 4px;
}