.vitrine-container {
    max-width: 910px !important;

    img {
        width: 100%;
    }
}

.tituloBasicoAvulso {
    font-family: "Bellfort";
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    margin: 0;
    text-transform: uppercase;
    color: #4FBE95;
}
.splide__arrow svg {
    fill: #4FBE95;
}
.splide__pagination__page.is-active {
    background: #4FBE95;
}
.splide__pagination {
    bottom: -30px;
}
.subtituloAvulso {
    font-family: "Bellfort";
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
    text-transform: uppercase;
    color: #54412F;
}
.textAvulso {
    font-family: OpenSans;
    font-size: 16px;
    line-height: 21px;
    margin: 0;
    color: #54412F;
}

.container-textAvulso {
    font-family: OpenSans;
    font-size: 16px;
    line-height: 21px;
    margin: 0;
    color: #54412F;
    min-height: 261px;
}

.container-dadosPessoais {
    min-height: 245px;
}

.textAvulsoSm {
    font-family: OpenSans;
    font-size: 14px;
    margin: 0;
    color: #54412F;
}

.radioBtn {
    padding: 8px;
}

.radioContainer {
    margin-top: 8px;
    width: 100%;
    
}

.radioBtnSubstituicao {
    padding: 32px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    width: 100%;

    label {
        width: 100%;
        cursor: pointer;
    }

    div {
        line-height: 1.375;
    }

    & + div {
        margin-top: 8px;
    }
}

.radioContainer div input {
    display: none;
}

.radioContainer div input + div:before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    vertical-align: middle;
    background: #fff;
    border: 2px solid #e0e0e0;
    border-radius: 50%;
    margin-right: 8px;
}

.radioContainer div input:checked + div:before {
    background: #FFF;
    box-sizing: border-box;
    border: 4.85px solid #4FBE95;
}

.textRadioBtnWithContainer {
    margin-left: 0;

    > div {
        display: inline;
    }
}

.textRadioBtn {
    //margin: 0;
    margin-left: 21px;
}
