.titulo-confirmacao {
  font-family: "OpenSans";
  font-size: 32px;
  line-height: 34px;
}

.green {
  color: #54be95;
}

.red {
  color: #db4139;
}

.container-hortifruti {
  max-width: 448px;
  margin: auto;
}

.listItemStatus {
  font-size: 16px;
  font-family: OpenSans;
  margin: 0px;
}

.listTitle {
  font-size: 24px;
  line-height: 24px;
  font-family: Bellfort;
  font-weight: bold;
  margin: 0px;
}

.listItem {
  border: solid;
  border-width: 1px;
  border-color: #D4D4D4;
  border-radius: 4px;
  padding: 8px 0px;
}

.listItemEndereco {
  width: 100%;
  min-height: 76px;
  border: solid;
  border-width: 1px;
  border-color: #D4D4D4;
  border-radius: 4px;
  
  position: relative;
  
  padding: 0px 16px 0px; 

  cursor: pointer;
}

.containerSizeEndereco {
  min-height: 55px;
}

.listItemEndereco:hover {
  background-color: #FAFAFA;
}

.listItemEndereco:active {
  border-color: #4FBE95;
}
.selecaoloja {
  border:solid;
  border-width:1;
  padding:16; 
  border-radius: 4;
  border-color:#E0E0E0; 
}

.selecaoloja:hover {
  border-color: #FAFAFA;
}

.selectedItem {
  border-color: #4FBE95 !important;
}

.listItemText {
  font-size: 14px;
  font-family: OpenSans;
  margin: 0px;
}

.listTextOverflowHidden {
  font-size: 14px;
  font-family: OpenSans;
  line-height: 150%;
  margin: 0px;
}


.listItemTextBold {
  font-family: OpenSans;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
}

.brownButton {
  font-family: OpenSans;
  font-size: 16px;
  padding: 12px;
  margin: 32px 0;
  background-color: #54412F;
  border: none;
  border-radius: 4px;
  color: #FFFFFF;
  width: -webkit-fill-available;
}

.brownButton:hover {
  background-color: #614B36;
}

.brownButton:active {
  background-color: #3B2D21;
}

.greenButton {
  font-family: OpenSans;
  font-size: 16px;
  padding: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #24A076;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  color: #FFFFFF;
  width:calc(100% - 32PX);
  margin: 16px auto;
  max-width: 450px;

}
.greenButton:disabled{
  background-color: #EEEEEE;
  color: #adadad;
  pointer-events: none;
}

.greenButton:hover{
  color: #FFFFFF;
}

.imgBtn {
  margin-right: 8px;
}

.imgBtn:active path {
  fill: red;
  margin: 100px;
}

.greenButton:hover {
  text-decoration: none;
  background-color: #5CB094;
}

.greenButton:active {
  background-color: #186E51;
}

.deleteItem {
  opacity: 0.4;
  border-radius: 4px;
}

.deleteItem:hover {
  opacity: 1;
}

.deleteItem:active {
  background-color: #EEEEEE;
}

.lightGreenButton {
  text-decoration: none !important;
  font-family: OpenSans;
  font-size: 16px;
  padding: 12px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: bold;
  background-color: #E5F5EF;
  color: #4FBE95;
  width: -webkit-fill-available;
  margin-bottom: 8px;
}

.lightGreenButton:hover {
  background-color: #5CB094;
  color: #FFFFFF;
}

.lightGreenButton:active {
  background-color: #186E51;
  color: #FFFFFF;
}

.buttonNormal {
  font-family: OpenSans;
  border-radius: 4px;
  font-size: 20px;
  color: #ffffff !important;
  box-shadow: transparent;
  background-color: #54412f !important;
  width: 100%;
  max-width: 350px;
  height: 50px;
  align-items: center !important;
  display: flex !important;
  justify-content: center !important;
}

.buttonNormal:hover {
  color: #54412f !important;
  border-width: 2px !important;
  border-color: #54412f !important;
  background-color: transparent !important;
}

.buttonTxt {
  font-family: OpenSans;
  border: none !important;
  color: #54412f !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  box-shadow: transparent;
  background-color: transparent !important;
  padding: 0 !important;
  width: 100%;
  height: 50px;
  max-width: 350px;
  align-items: center !important;
  display: flex !important;
  justify-content: center !important;
}

.buttonTxt:hover {
  border-width: 2px !important;
  border-color: #54412f !important;
}

.buttonselecao{
  background-color: #24A076;
}

.buttonselecao:hover{
background-color: #5CB094;
}
.buttonselecao:active{
  background-color: #186E51;
}

.ButtonSelect{
  position: sticky !important;
  background-color: #ffffff;
  width: 100% ;
  bottom: 0;
}

.Selecaoloja {
  margin-top: 16px;
  margin-bottom: 96px;
}

.listItemLabel { 
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left: 0;
  
  padding: 16px; 
  
  cursor: pointer;

  display: flex;
  align-items: center;
}

/* .listItemCol {
  text-align: left;
  padding-left: 1px;

  width: 100%;
  height: 100%;
} */
